import { Environment } from './environment.type';

export const environment: Environment = {
  auth0: {
    loginUrl: 'https://sandbox-login.bannerflow.com',
    clientId: 'Z1QtaJRvW4rxw4cOr68Z6wyKiEK4RafH'
  },
  origins: {
    apiUri: 'https://sandbox-api.bannerflow.com/notification-service/api'
  },
  signalR: {
    enabled: true,
    url: 'https://sandbox-api.bannerflow.com/notification-service/hub'
  }
};
